import React, { useLayoutEffect } from 'react';
import ReactFullpage from '@fullpage/react-fullpage';
import { graphql } from 'gatsby';
import { Layout, FullpageSection, Seo } from '../../shared/ui';
import './index.css';
import { useAnimation } from '../../shared/hooks';

const Branded: React.FC = (props) => {
  // @ts-ignore
  const data = props.data.allContentfulBrandsList.nodes[0].brands;
  const titles = data.map((brand) => brand.title);
  const slugs = data.map((brand) => brand.slug);

  useLayoutEffect(() => {
    document.title = data.title;
    // console.log(data.title);
  }, []);

  // useEffect(() => {
  //   if (typeof window !== 'undefined') {
  //     const playingVideos =
  //       document.querySelectorAll<HTMLVideoElement>('.playing-video') || null;

  //     playingVideos.forEach((vid) => {
  //       vid?.addEventListener('canplay', () => vid.play());
  //     });

  //     // return () => {
  //     //   playingVideos.forEach((vid) => {
  //     //     vid?.removeEventListener('canplay', () => vid.play());
  //     //   });
  //     // };
  //   }
  //   return () => {};
  // }, []);

  const { onLeave } = useAnimation();

  return (
    <Layout transparentNav topMargin={false}>
      <Seo title="Branded - Bolt Tv" />
      <ReactFullpage
        anchors={slugs}
        navigation
        navigationTooltips={titles}
        lazyLoading
        onLeave={onLeave}
        scrollingSpeed={400}
        credits={{
          enabled: true,
          label: 'Made with fullpage.js',
          position: 'right',
        }}
        render={() => (
          <ReactFullpage.Wrapper>
            {data?.map((brand) => (
              <FullpageSection
                key={brand.id}
                brandedImg={brand?.bannerSliderImg || null}
                promoVideo={brand?.videoPromo?.url || ''}
                director={brand?.director || ''}
                slug={brand.slug}
                title={brand.title}
                category={brand.category}
                subtitle={brand.subtitle}
                logoUrl={brand.logo?.url}
              />
            ))}
          </ReactFullpage.Wrapper>
        )}
      />
    </Layout>
  );
};

export const query = graphql`
  query QueryBrands {
    allContentfulBrandsList {
      nodes {
        brands {
          title
          slug
          category
          subtitle
          videoPromo {
            url
          }
          logo {
            url
          }
          director
          mainImage {
            url
            title
          }
          id
          bannerSliderImg {
            title
            contentful_id
            gatsbyImage(height: 1440, width: 2560)
          }
        }
      }
    }
  }
`;

export default Branded;
